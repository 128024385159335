<script src="../../../../../mixins/search.js"></script>
<template>
    <div class="content-body">
        <div class="row mt-2 mg-sm-0 no-print">
        <div class="col-6">
            <div class="content-header pd-l-0">
                <div>
                    <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#">Account</a></li>
                        <li class="breadcrumb-item text-capitalize active" aria-current="page">Manage Account</li>
                    </ol>
                    </nav>
                    <h4 class="content-title content-title-xs">Manage Accounts</h4>
                </div>
            </div><!-- content-header -->
        </div><!-- col -->
        <div class="col-6 tx-right">
            <button type="button" v-if="checkSinglePermission('create_manage_account')" class="btn btn-primary mg-t-8 mg-b-0" @click="$store.dispatch('modalWithMode', { id: 'addAccount', mode: 'create' })">Add Account</button>
        </div><!-- col -->
       
      </div><!-- row -->
      <div class="bg-theam-secondary table-search-head pt-0">
            <div class="row">
                <div class="filter-input col-md-9 d-block mr-0 pr-0">
                    <select v-model="params.size" @change="searchFilter" id="manage_account_filter_size" class="mr-2 mg-t-5">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                    <select v-model="params.type" class="mr-2 mg-t-5" id="manage_account_filter_type" @change="searchFilter">
                        <option value="">All Account Type</option>
                        <option v-for="(type,index) in accountElements" :key="index" :value="type.id">{{type.title}}</option>
                    </select>
                </div>
                <div class="col-md-3 ml-0 pl-0 mg-t-5">
                    <input type="text" v-model="params.searched"  class="float-right" id="manage_account_search" placeholder="Search..." @keyup="searchFilter">
                </div>
            </div>
        </div>
        <div class="row py-0">
            <div class="col-md-12 overflow-x-auto">
                <table class="table table2 table-hover" id="tableData">
                    <thead>
                    <tr>
                        <th class="wd-5p table-start-item">S.N.</th>
                        <th class="wp-15p">Account ID</th>
                        <th class="wd-20p">Account Type</th>
                        <th class="wd-30p">Account Name</th>
                        <th class="wd-20p">Balance Amount</th>
                        <th class="wd-10p text-center table-end-item" v-if="checkIfAnyPermission(['view_manage_account','create_manage_account','edit_manage_account','delete_manage_account'])">Action</th>
                    </tr>
                    </thead>
                    <tbody v-if="!loading && accountLists.length > 0" >
                        <tr v-for="(account, index) in accountLists" :key="index">
                            <th scope="row" class="table-start-account">{{ pageSerialNo+index }}.</th>
                            <th>#{{(account.id)}}</th>
                            <td>{{account.type.title}} </td>
                            <td>{{account.account_name}} <span v-if="account.depreciation"> -Depreciation:{{account.depreciation}}%</span></td>
                            <td>{{parseDigitForSlip(account.closing_balance)}}</td>
                            <td class="text-center table-end-item" v-if="checkIfAnyPermission(['view_manage_account','create_manage_account','edit_manage_account','delete_manage_account'])" >
                                <!-- <a href="javascript:;" class="mr-3" :id="'manageAccountView'+account.id">
                                    <i class="fas fa-eye tx-success"></i>
                                </a> -->
                                <a href="javascript:;" class="mr-3" :id="'manageAccountEdit'+account.id" v-if="checkSinglePermission('edit_manage_account')" @click="edit('editAccount', account.id)">
                                    <i class="fa fa-edit"></i>
                                </a>
                                <a href="javascript:;" :id="'manageAccountDelete'+account.id" v-if="checkSinglePermission('delete_manage_account')" @click="drop(account.id)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </td>
                       </tr>
                    </tbody>
                     <tbody v-else-if="!loading && accountLists.length == 0">
                        <tr>
                        <td  colspan="11" style="text-align:center;">
                            No records found.
                        </td>
                        </tr>
                    </tbody>
                    <tbody v-else-if="loading">
                        <tr>
                        <td  colspan="11" style="text-align:center;">
                            Loading.....
                        </td>
                        </tr>
                        </tbody>
                        <tbody v-else-if="error">
                            <td colspan="6" class="text-center">{{error_message}}</td>
                        </tbody>
                </table>
            </div>
        </div>
        <Paginate v-model="page" :pageCount="totalPageCount"
                :containerClass="'pagination'"
                :clickHandler="clickCallback"
                v-if="totalPageCount > 1"
                />
        <Create @parent-event="getData()" />
        <Edit @parent-event="getData()" />
    </div>
</template>
<script>
/* eslint-disable */
import Create from "./create";
import Edit from "./edit";
import {mapGetters} from "vuex";
import Services from "./Services/Services";
import Paginate from 'vuejs-paginate';
import _ from "lodash";
export default {
components:{
    Create,Edit,Paginate
},
computed: {
    ...mapGetters([
        "icons",
        "dataId",
        "dataLists",
        "removeList",
        "dataLists5",
        "pagination",
        "eventMessage",
    ]),
    ...mapGetters("manageAccounts",["accountLists","accountElements"])
},
data(){
    return{
        params:{
          size:10,
          searched:'',
          offset:0,
          branch:'',
          type:'',
      },
      totalPageCount:0,
      pageSerialNo:1,
      error_message:'',
      loading:true,
      error:false,
      page : 1,
    }
},
methods: {
    clickCallback: function(page) {
      if(page == 1){
          this.pageSerialNo = 1;
      }else{
          this.pageSerialNo = ((page-1) * this.params.size) + 1 ;
      }
      this.params.offset = (page-1) * this.params.size;
      this.getData();
    },
    edit(modal, id) {
        this.$store.commit("setDataId", id);
        this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
     drop(id) {
       this.$swal({
        title: 'Do you really want to delete Account ?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Delete',
          cancelButtonText: 'No',
          showLoaderOnConfirm: true,
          preConfirm:() => {
          // delete lead
             return Services.deleteAccount(id).then((response) => {
              if(response.status == 200){
                  let success = response.data.success_message;
                  this.setNotification(success);
              }
              this.getData();
          }).catch(error => { 
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if(error.response.status == 500){
                  let err = error.response.data.error_message;
                  this.setNotification(err);
              }
          })
          }
      })
    },
    getData(){
        //this.$store.commit("getData", `api/accounts/size/${this.filter.size}`);
        this.loading = true;
        Services.getAccountList(this.params).then(res=>{
            this.$store.commit("manageAccounts/setAccountLists",res.data.data.data);
            this.getElements();
            this.totalPageCount = res.data.data.total_no_of_pages;
        }).catch(err=>{
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
            } else {
              this.error_message = "Error Fetching data from the server.";
            }
            this.error=true;
        }).finally(()=>{
            this.loading= false;
        });
    },
    getElements(){
        Services.getAccountElements().then(res=>{
            this.$store.commit("manageAccounts/setAccountElements",res.data.data);
        }).catch(res=>{
            console.log(res);
        });
    },
     searchFilter: _.debounce(function(){
        this.loading = true;
        this.pageSerialNo =1;
        this.params.offset = 0;
        this.page=1;
         Services.getAccountList(this.params).then(res=>{
            this.$store.commit("manageAccounts/setAccountLists",res.data.data.data);
            this.totalPageCount = res.data.data.total_no_of_pages;
            this.loading=false;
        }).catch(err=>{
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
            } else {
              this.error_message = "Error Fetching data from the server.";
            }
            this.error = true;
            let error = err.response.data.error_message;
            this.setNotification(error);
        });
      },1000),
     setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
},
mounted() {
    this.getData();
},
}
</script>