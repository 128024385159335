<template>
    <div class="col-md-12">
        <div class="modal fade modal_cust show" v-if="modalId=='addAccount'" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" style="display: block;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Add Account</h5>
                        <a class="close"  @click="$store.dispatch('modalClose','addNewItem')">
                            <span aria-hidden="true">×</span>
                        </a>
                    </div>
                    <form @submit.prevent="submit" method="post" class="bg-theam-secondary" enctype="multipart/form-data">
                        <div class="modal-body row">
                            <div class="col-md-6 pd-r-7-5">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="group">
                                            <label class="group-label">Basic Information</label>
                                            <div class="group-attribute-container">
                                                <div class="row mb-2">
                                                    <div class="col-md-5 mr-0 pr-0">Account Type *</div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <select v-model="formData.account_type" id="manage_account_type" class="form-control" required>
                                                            <option value="" disabled>Account Type</option>
                                                            <option v-for="(type,index) in accountElements" :key="index" :value="type.id">{{type.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-5 mr-0 pr-0">Account Name *</div>
                                                    <div class="col-md-7 input-group ml-0 pl-0">
                                                        <input v-model="formData.account_name" class="form-control" id="manage_account_name" placeholder="Account Name" required/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 pd-l-7-5">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="group">
                                            <label class="group-label">Amount Detail</label>
                                            <div class="group-attribute-container">
                                                <div class="row mb-2">
                                                    <div class="col-md-5 mr-0 pr-0">Opening Balance</div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <input type="number" min="0" step="any" v-model="formData.opening_balance" class="form-control" id="manage_account_detail"
                                                                placeholder="NRP 0.00">
                                                    </div>
                                                </div>
                                                <div class="row" v-if="formData.account_type==8">
                                                    <div class="col-md-5 mr-0 pr-0">Depreciation *</div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <div class="input-group">
                                                            <input
                                                            type="number"
                                                            min="0"
                                                            max="100"
                                                            step="any"
                                                            class="form-control"
                                                            id="manage_account_description"
                                                            placeholder="0.00"
                                                            v-model="formData.depreciation"
                                                            required
                                                            />
                                                            <div class="input-group-prepend">
                                                            <span class="input-group-text" style="min-width: 0px">%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-12 mt-2" v-if="formData.account_type==8"><span class="tx-danger">Note: </span>To Check Depreciation Percentage For Various Assets <a href="#">Click Here</a></div> -->
                            <div class="col-md-12 text-right mt-3">
                                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">{{(disableSubmitButton)?'Submitting...':'Submit'}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import Services from "./Services/Services"
    export default {
        data() {
            return {
                formData: {
                    id: null,
                    account_type:'',
                    account_name:null,
                    opening_balance:null,
                    closing_balance:null,
                    depreciation:null,
                },
                disableSubmitButton :false,
            };
        },
        computed: {
            ...mapGetters([
                "processing",
                "eventMessage",
                "dataLists",
                "dataLists5",
                "dataId",
                "modalId",
                "modalMode",
            ]),
            ...mapGetters("manageAccounts",["accountLists","accountElements"])
        },
        mounted() {

        },
        methods: {
            submit() {
                this.disableSubmitButton = true;
                if (this.modalMode == "create") {
                    Services.createNewAccount(this.formData).then(res=>{
                        this.$store.dispatch("modalClose");
                        this.setNotification(res.data.success_message);
                        this.$emit('parent-event');
                    }).catch(err=>{
                        this.error=false;
                        console.log(err);
                        if(err.response.status == 422){
                            let error = Object.values(err.response.data.errors).flat();
                            this.setNotification(error);
                        }else{
                            let error = err.response.data.error_message;
                            this.setNotification(error);
                        }
                    }).finally(()=>{
                        this.disableSubmitButton=false;
                    })
                } else if (this.modalMode == "edit") {
                    this.$store.commit("setApiUrl", `api/account/manage/${this.formData.id}`);
                    this.$store.commit("updateData", this.formData);
                }
            },
            clearData() {
                this.formData.id = null;
                this.formData.account_type='';
                this.formData.account_name=null;
                this.formData.opening_balance=null;
                this.formData.closing_balance=null;
                this.formData.depreciation=null;
            },
            setNotification(msg){
                this.$store.dispatch("enableMessage");
                this.$store.commit("setEventMessage",msg);
            },
            getElements(){
                Services.getAccountElements().then(res=>{
                    this.$store.commit("manageAccounts/setAccountElements",res.data.data);
                }).catch(res=>{
                    console.log(res);
                });
            },
        },
        watch: {
            modalId(value){
                if(value == "addAccount"){
                    this.getElements();
                    this.clearData();
                }
            },
        },
    };
</script>
