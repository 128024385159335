import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class ManageAccountService{
    getAccountList(param){
        const url = `api/accounts/filter`
        const params = {
            'page-index': param.size,
            'branch':param.branch,
            'searched':param.searched,
            'type':param.type,
            'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getAccountElements(){
        const url = `api/account/types`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    createNewAccount(formdata){
        const url = `api/account/manage/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formdata,headers);
    }
    updateAccount(formdata,id){
        const url = `api/account/manage/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formdata,headers);
    }
    deleteAccount(id){
        const url = `api/account/manage/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.delete(url,headers);
    }

}
export default new ManageAccountService();